import React from 'react';
import lazySizes from 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import AOS from 'aos';
import 'aos/dist/aos.css';
lazySizes.cfg.preloadAfterLoad= true;
lazySizes.cfg.expand = 500;

class Post extends React.Component {
  constructor() {
    super();
    this.state = { size: {width:false,height:false} };
  }
  async componentDidMount() {
    const loadImage = (src) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => resolve(img);
        img.onerror = (e) => reject(e);
        img.src = src;
      });
    };
    const res = await loadImage(this.props.post + "?q=0&w=200");
    this.setState({size: {width:res.width,height:res.height}})
    AOS.init({
      duration : 1000,
    })
  }
  componentWillUnmount() {
  }



  render() {
    const _this = this.props;
    const comp = "?auto=format,compress";
    const sizePcR = "&w=" + this.props.size[0];
    const sizePc = "&w=" + (this.props.size[0]/2);
    const sizeTb = "&w=" + this.props.size[1];
    const sizeSp = "&w=" + this.props.size[2];
    const dummy = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVQI12NgYAAAAAMAASDVlMcAAAAASUVORK5CYII=";
    let imageClass = this.state.size.width > this.state.size.height ? "yoko" : "tate";
    imageClass = this.state.size.width ===  this.state.size.height ? "sikaku" : imageClass;
    imageClass += ' image_wrapp'
    const paddingTop = {
      paddingTop:this.state.size.height/this.state.size.width * 100 + "%"
    }

    return (
        <div className={imageClass}>
          <div className="image_inner" style={paddingTop} data-aos='fade' data-aos-anchor-placement="top-bottom" data-aos-offset="100">
            <picture>
              <source media="(min-width:980px)" srcSet={dummy}  data-srcset={`${_this.post}${comp}${sizePc} 1x,${_this.post}${comp}${sizePcR} 2x`} />
              <source media="(min-width:768px)" srcSet={dummy} data-srcset={`${_this.post}${comp}${sizeTb} 1x,${_this.post}${comp}${sizeTb} 2x`} />
              <img className="lazyload" src={dummy} data-src={`${_this.post}${comp}`} data-srcset={`${_this.post}${comp}${sizeSp} 1x,${_this.post}${comp}${sizeSp} 2x`} alt={_this.alt} />
            </picture>
          </div>
        </div>
    )
  }
}

export default Post